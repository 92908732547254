var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "vx-card",
    {
      attrs: {
        title: _vm.id === null ? _vm.$t("news_create") : _vm.$t("news_edit"),
      },
    },
    [
      _c("div", { staticClass: "vx-row mb-6" }, [
        _c(
          "div",
          { staticClass: "vx-col w-full" },
          [
            _c("vs-input", {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required|max:191",
                  expression: "'required|max:191'",
                },
              ],
              staticClass: "w-full",
              attrs: { label: _vm.$t("news_title"), name: "title" },
              model: {
                value: _vm.title,
                callback: function ($$v) {
                  _vm.title = $$v
                },
                expression: "title",
              },
            }),
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.errors.has("title"),
                    expression: "errors.has('title')",
                  },
                ],
                staticClass: "text-danger text-sm",
              },
              [_vm._v(" " + _vm._s(_vm.errors.first("title")) + " ")]
            ),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "vx-row mb-6" }, [
        _c(
          "div",
          { staticClass: "vx-col w-full" },
          [
            _c("vs-input", {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required",
                  expression: "'required'",
                },
              ],
              staticClass: "w-full",
              attrs: { label: _vm.$t("news_description"), name: "description" },
              model: {
                value: _vm.description,
                callback: function ($$v) {
                  _vm.description = $$v
                },
                expression: "description",
              },
            }),
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.errors.has("description"),
                    expression: "errors.has('description')",
                  },
                ],
                staticClass: "text-danger text-sm",
              },
              [_vm._v(" " + _vm._s(_vm.errors.first("description")) + " ")]
            ),
          ],
          1
        ),
      ]),
      _c(
        "vs-select",
        {
          staticClass: "mb-6",
          staticStyle: { width: "300px" },
          attrs: { label: _vm.$t("selecione-para-quem-deseja-enviar-o-aviso") },
          model: {
            value: _vm.model_id,
            callback: function ($$v) {
              _vm.model_id = $$v
            },
            expression: "model_id",
          },
        },
        _vm._l(_vm.model_type, function (type, index) {
          return _c("vs-select-item", {
            key: index,
            attrs: { value: type.value, text: type.text },
          })
        }),
        1
      ),
      _c("div", { staticClass: "vx-row mb-6" }, [
        _c(
          "div",
          { staticClass: "vx-col sm:w-1/5" },
          [
            _c("label", [_vm._v(_vm._s(_vm.$t("news_start_at")))]),
            _c("datetime", {
              attrs: {
                "input-class": "vs-inputx vs-input--input normal",
                type: "datetime",
                format: "dd/MM/yyyy hh:mm",
                "value-zone": "America/Sao_Paulo",
                "use24-hour": "",
              },
              model: {
                value: _vm.start_at,
                callback: function ($$v) {
                  _vm.start_at = $$v
                },
                expression: "start_at",
              },
            }),
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.errors.has("start_at"),
                    expression: "errors.has('start_at')",
                  },
                ],
                staticClass: "text-danger text-sm",
              },
              [_vm._v(" " + _vm._s(_vm.errors.first("start_at")) + " ")]
            ),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "vx-row mb-6" }, [
        _c(
          "div",
          { staticClass: "vx-col sm:w-1/5" },
          [
            _c("label", [_vm._v(_vm._s(_vm.$t("news_finish_at")))]),
            _c("datetime", {
              attrs: {
                "input-class": "vs-inputx vs-input--input normal",
                type: "datetime",
                format: "dd/MM/yyyy hh:mm",
                "value-zone": "America/Sao_Paulo",
                "use24-hour": "",
              },
              model: {
                value: _vm.finish_at,
                callback: function ($$v) {
                  _vm.finish_at = $$v
                },
                expression: "finish_at",
              },
            }),
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.errors.has("finish_at"),
                    expression: "errors.has('finish_at')",
                  },
                ],
                staticClass: "text-danger text-sm",
              },
              [_vm._v(_vm._s(_vm.errors.first("finish_at")))]
            ),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "vx-row" }, [
        _c(
          "div",
          { staticClass: "vx-col w-full" },
          [
            _c(
              "vs-button",
              {
                staticClass: "float-right",
                attrs: { color: "primary", type: "border" },
                on: { click: _vm.cancel },
              },
              [_vm._v(_vm._s(_vm.$t("action.cancel")))]
            ),
            _c(
              "vs-button",
              {
                directives: [
                  {
                    name: "permission",
                    rawName: "v-permission",
                    value: "news.create",
                    expression: "'news.create'",
                  },
                ],
                staticClass: "float-right mr-2",
                attrs: { disabled: !_vm.validateForm },
                on: { click: _vm.create },
              },
              [_vm._v(_vm._s(_vm.$t("action.save")))]
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }