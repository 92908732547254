<template>
  <vx-card 
    :title="id === null ? $t('news_create') : $t('news_edit')"
  >
    <!--   <p> Testing Data </p>
   <div v-for="type in model_type" :key="type.id"> 
       <p> {{type}} </p>
   </div>
   -->
    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <vs-input
          class="w-full"
          :label="$t('news_title')"
          v-model="title"
          name="title"
          v-validate="'required|max:191'"
        />
        <span class="text-danger text-sm" v-show="errors.has('title')">
          {{ errors.first('title') }}
        </span>
      </div>
    </div>

    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <vs-input
          class="w-full"
          :label="$t('news_description')"
          v-model="description"
          name="description"
          v-validate="'required'"
        />
        <span class="text-danger text-sm" v-show="errors.has('description')">
          {{ errors.first('description') }}
        </span>
      </div>
    </div>

     <vs-select
      style="width: 300px;"
      :label="$t('selecione-para-quem-deseja-enviar-o-aviso')"
      v-model="model_id"
      class="mb-6"
    >
      <vs-select-item :key="index" :value="type.value" :text="type.text" v-for="type,index in model_type"/> 
    </vs-select>

   <div class="vx-row mb-6">
      <div class="vx-col sm:w-1/5">
        <label>{{ $t('news_start_at') }}</label>
        <datetime
          v-model="start_at"
          input-class="vs-inputx vs-input--input normal"
          type="datetime"
          format="dd/MM/yyyy hh:mm"
          value-zone="America/Sao_Paulo"
          use24-hour
        >
        </datetime>
        <span class="text-danger text-sm" v-show="errors.has('start_at')">
          {{ errors.first('start_at') }}
        </span>
      </div>
    </div>


    <div class="vx-row mb-6">
      <div class="vx-col sm:w-1/5">
        <label>{{ $t('news_finish_at') }}</label>
        <datetime
          v-model="finish_at"
          input-class="vs-inputx vs-input--input normal"
          type="datetime"
          format="dd/MM/yyyy hh:mm"
          value-zone="America/Sao_Paulo"
          use24-hour
        >
        </datetime>
        <span class="text-danger text-sm" v-show="errors.has('finish_at')">{{
          errors.first('finish_at')
        }}</span>
      </div>
    </div>
    <div class="vx-row">
      <div class="vx-col w-full">
        <vs-button
          class="float-right"
          color="primary"
          type="border"
          @click="cancel"
          >{{ $t('action.cancel') }}</vs-button
        >
        <vs-button
          v-permission="'news.create'"
          class="float-right mr-2"
          @click="create"
          :disabled="!validateForm"
          >{{ $t('action.save') }}</vs-button
        >
      </div>
    </div>
  </vx-card>
</template>

<script>
import NewsService from '@/services/api/NewsService'
import { Datetime } from 'vue-datetime'
import 'vue-datetime/dist/vue-datetime.css'

export default {
  props: {
    id: {
      type: Number,
      default: null
    }
  },
  components: {
    datetime: Datetime
  },

  data: () => ({
    service: null,
    id: null,
    title: '',
    description: '',
    start_at: '',
    finish_at: '',
    model_id:1,
    model_type: function () {
      return [
        {text:this.$t('general'), value: 1},
        {text:this.$t('classe'), value: 2},
        {text:this.$t('usuario'), value: '3'}
      ]
    }
  }),
  computed: {
    validateForm() {
      return !this.isEmpty(this.title && this.description)
    }
  },
  methods: {
    create() {
      this.clearErrors(this.$validator)
      this.$vs.loading()

      this.service
        .createOrUpdate({
          id: this.id,
          title: this.title,
          description: this.description,
          start_at: this.start_at,
          finish_at: this.finish_at
        })
        .then(
          data => {
            this.$vs.loading.close()
            this.notifySuccess(this.$vs, this.$t('operacao-realizada-com-sucesso'))
            this.$router.push('/news/:id/edit')
          },
          error => {
            this.$vs.loading.close()
            this.showErrors(this.$validator, error)
            this.notifyError(
              this.$vs,
              this.$t('nao-foi-possivel-realizar-esta-operacao')
            )
          }
        )
    },
    cancel() {
      this.$router.push('/news')
    },
    getData(id) {
      this.$vs.loading()
      this.service.read(id).then(
        data => {
          this.title = data.title
          this.description = data.description
          this.start_at = data.start_at_formatted
          this.finish_at = data.finish_at_formatted
          this.$vs.loading.close()
        },
        error => {
          this.$vs.loading.close()
        }
      )
    }
  },
  beforeMount() {
    this.service = NewsService.build(this.$vs)
    if (this.id) {
      this.getData(this.id)
    }
   
  },
}
</script>
